<template>
  <div class="row">
    <div class="col-12">
      <c-table
        ref="grid"
        title="작업계획별 작업자의 할당시간"
        :columns="grid.columns"
        :merge="grid.merge"
        :data="grid.data"
        :gridHeight="grid.height"
        :editable="false"
        :hideBottom="true"
        :isExcelDown="false"
        :filtering="false"
        :isFullScreen="false"
        :columnSetting="false"
      >
        <template v-slot:customArea="{ props, col }">
          <template>
            <span v-if="getDate(props.row, col)">
                <font style="font-size:1.0em;font-weight:700;" :color="Number(getDate(props.row, col)) >= 5 ? '#C10015' : '#180dde'">
                  {{ getDate(props.row, col) }}
                </font>
                <q-badge v-if="Number(getDate(props.row, col)) >= 5" color="negative" label="과중" class="blinking" />
            </span>
            <span v-else>
              <q-icon name="close" class="text-red"/> 
            </span>
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  props: {
    param: {
      type: Object,
      default: () => ({
        workResultId: '',
        workResultWorkId: '',
        equips: [],
        worksData: {},
      }),
    },
    click: {
      type: String,
      default: '',
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'workResultWorkId' },
          { index: 1, colName: 'workResultWorkId' },
          { index: 2, colName: 'workResultWorkId' },
          // { index: 3, colName: 'workResultWorkId' },
        ],
        columns: [
        ],
        data: [],
        // height: '320px',
      },
      getUrl: '',
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.$_.cloneDeep(this.param)
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    click() {
      this.getWorkers();
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.getUrl = selectConfig.wod.workorder.result.workers.url;

      this.getWorkers();
    },
    getWorkers() {
      if (this.param.workResultId) {
        this.$http.url = this.getUrl;
        this.$http.param = {
          workResultId: this.param.workResultId,
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
          if (this.grid.data && this.grid.data.length > 0) {
          this.$_.forEach(this.grid.data, _item => {
            if (_item.dailyList && _item.dailyList.length > 0) {
              this.$_.forEach(_item.dailyList, __item => {
                _item[__item.workDate] = __item.workTime
              })
            }
          })
          this.setWorkerColumns();
        }
        },);
      }
    },
    setWorkerColumns() {
      let _columns = [
        {
          name: 'sortOrder',
          field: 'sortOrder',
          label: '항목',
          align: 'center',
          style: 'width:60px',
          sortable: false,
        },
        {
          name: 'workResultWorkName',
          field: 'workResultWorkName',
          label: '작업명',
          style: 'width:150px',
          align: 'left',
          sortable: false,
        },
        {
          name: 'workDtsStr',
          field: 'workDtsStr',
          label: '작업기간',
          align: 'center',
          style: 'width:120px',
          sortable: false,
        },
        {
          name: 'workerName',
          field: 'workerName',
          label: '작업자',
          style: 'width:120px',
          align: 'center',
          sortable: false,
        },
      ]
      let startDt = this.$_.map(this.grid.data, 'workStartDt').reduce((prev,curr) => {
        // 이전것과 비교해 더 큰 것을 리턴
          return new Date(prev).getTime() <= new Date(curr).getTime() ? curr : prev;
      })

      let endDt = this.$_.map(this.grid.data, 'workEndDt').reduce((prev,curr) => {
        // 이전것과 비교해 더 큰 것을 리턴
          return new Date(prev).getTime() <= new Date(curr).getTime() ? curr : prev;
      })

      let dayCnt = this.$comm.getDatediff(this.$comm.moment(startDt), this.$comm.moment(endDt))

      for (let i=0; i<=dayCnt; i++) {
        let date = this.$comm.getCalculatedDate(this.$comm.moment(startDt), i+'d', 'YYYY-MM-DD')
        let month = date.substring(5);
         
        _columns.push({
          name: date,
          field: date,
          label: month,
          style: 'width:50px',
          align: 'center',
          type: 'custom',
          sortable: false,
        },)
      }
      this.grid.columns = _columns;
    },
    getDate(row, col) {
      if (row.workStartDt <= col.name && col.name <= row.workEndDt) {
        return row[col.name] ? row[col.name] : '0'
      } else {
        return ''
      }
    },
  }
};
</script>