var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-table", {
            ref: "grid",
            attrs: {
              title: "작업계획별 작업자의 할당시간",
              columns: _vm.grid.columns,
              merge: _vm.grid.merge,
              data: _vm.grid.data,
              gridHeight: _vm.grid.height,
              editable: false,
              hideBottom: true,
              isExcelDown: false,
              filtering: false,
              isFullScreen: false,
              columnSetting: false,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    [
                      _vm.getDate(props.row, col)
                        ? _c(
                            "span",
                            [
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                  attrs: {
                                    color:
                                      Number(_vm.getDate(props.row, col)) >= 5
                                        ? "#C10015"
                                        : "#180dde",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getDate(props.row, col)) +
                                      " "
                                  ),
                                ]
                              ),
                              Number(_vm.getDate(props.row, col)) >= 5
                                ? _c("q-badge", {
                                    staticClass: "blinking",
                                    attrs: { color: "negative", label: "과중" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c(
                            "span",
                            [
                              _c("q-icon", {
                                staticClass: "text-red",
                                attrs: { name: "close" },
                              }),
                            ],
                            1
                          ),
                    ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }